export default {
	perPageText: "Mostrando :entries datos",
	infoText: "Mostrando :first hasta :last de :total datos",
	infoFilteredText: "Mostrando :first hasta :last de :filtered (filtrado de :total datos)",
	nextButtonText: "Siguiente",
	previousButtonText: "Anterior",
	paginationSearchText: "Ir a la página",
	paginationSearchButtonText: "IR",
	searchText: "buscar:",
	emptyTableText: "No se encontraron datos que coincidan con la búsqueda",
	downloadText: "exportar cómo:",
	downloadButtonText: "DESCARGAR"
};
